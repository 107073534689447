// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-us-mdx": () => import("./../../../src/pages/about-us.mdx" /* webpackChunkName: "component---src-pages-about-us-mdx" */),
  "component---src-pages-contact-us-mdx": () => import("./../../../src/pages/contact-us.mdx" /* webpackChunkName: "component---src-pages-contact-us-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-services-mdx": () => import("./../../../src/pages/services.mdx" /* webpackChunkName: "component---src-pages-services-mdx" */)
}

